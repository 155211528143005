import React from "react"

import SEO from "../components/seo"

function NotFoundPage() {
  return (
    <React.Fragment>
      {/* <Layout> */}
      <SEO title="404: Not found" />
      <h1>404: Not Found</h1>
      <p>Sei arrivado ad una pagina che non esiste 😢</p>
      {/* </Layout> */}
    </React.Fragment>
  )
}

export default NotFoundPage
